@import '../../../../scss/base/var';

.fallback {
  background-color: $color-text-light;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  color: white;
}

.repoAvatar {
  border-radius: 4px;
  flex: 1;
}